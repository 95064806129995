.root {
  width: 100%;
  padding-bottom: spacing(4);

  & > * {
    word-wrap: break-word;
  }
}

.embedWrapper {
  margin: 0;
}

.employeeWrapper {
  h3 ~ & {
    margin-top: spacing(2);
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @media ($phone-down) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.blockSection {
  margin-bottom: spacing(0);
}

.blockButton {
  display: block;
}

.tabsWithContentRoot {
  padding-bottom: 0;
}

.floatImageTextRendererContent {
  * {
    display: inline;
  }
}
